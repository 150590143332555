import React from 'react';
import { Navbar, Nav } from "react-bootstrap";
import logo_gasex from '../assets/img/logo_gasex.png';
const Header = () => {
    const cerrarSesion=()=>{
        localStorage.clear();
        window.location.href="./";
    }
    return ( 
        <header>
            <Navbar bg="light" variant="light" className="d-flex justify-content-between">
                
                <Navbar.Brand href="#home">
                    <img src={logo_gasex}
                     height="30"
                     className="d-inline-block align-top"
                     alt="logo_gasex"></img>
                    <small>Administración</small> 
                </Navbar.Brand>
                <Nav.Link eventKey={2} href="" onSelect={()=>{cerrarSesion()}}>
                   Cerrar Sesión
                </Nav.Link>
            </Navbar>
        </header>

     );
}
 
export default Header;