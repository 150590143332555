import React from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSprayCan,faTools,faUser } from '@fortawesome/free-solid-svg-icons';


const Dashboard = () => {
    //const [itemActivo,cambiarActivoItem]=useState(false);

   
    return ( 
       
        
                <>
                <ListGroup variant="flush" defaultActiveKey="#link1">
                <ListGroup.Item action href="#link1" ><FontAwesomeIcon icon={faSprayCan}  className="mr-2"/>Cilindros</ListGroup.Item>
                <ListGroup.Item action href="#link2"><FontAwesomeIcon icon={faTools}  className="mr-2"/>Valvulas</ListGroup.Item>
                <ListGroup.Item action href="#link3"><FontAwesomeIcon icon={faUser}  className="mr-2"/>Clientes</ListGroup.Item>
                
                </ListGroup>


                    
                </>


      
       
     );
}
 
export default Dashboard;