
import './App.css';
import {Route,Switch} from 'react-router-dom';
import FormInicioSesion from './componentes/Form_inicio_sesion';
import Dashboard from './componentes/Dashboard';



function App() {
  return (
    <main>
      <Switch>
        <Route path="/" component={FormInicioSesion} exact/>
        <Route path="/dashboard" component={Dashboard}/>
      </Switch>
   </main>
  );
}

export default App;
