import React from 'react';
import Header from './Header';
import Menu from './Menu';
import Cilindros from './Cilindros';
import { Col, Container, Row } from 'react-bootstrap';



const Dashboard = () => {

    if(!localStorage.getItem('User_Usuario')) {
        window.location.href="./";
        return null;
      }

 
          return ( 
              <>
            <Header></Header>
              <Container fluid>
                    <Row>
                        <Col className="p-0 vh-100 border" xs={2}>
                            <Menu></Menu>
                        </Col>
                        <Col>
                            <Cilindros></Cilindros>
                        </Col>
                        
                        
                    </Row>
                    
                    
              </Container>
              </>
           );
      
}
 
export default Dashboard;