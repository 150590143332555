import React, {useState} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';
import logo_gasex from '../assets/img/logo_gasex.png';
//import {NavLink} from 'react-router-dom';
import axios from 'axios';

const BaseURL='https://interno.gasex.cl/api/src/public/iniciarSesion';




const FormInicioSesion = () => {
    

    const [usuario,cambiarUsuario]=useState('');
    const [pasword,cambiarPasword]=useState('');
    const [msjError,FmsjeError]=useState('');
    const [visibilidad,cambiarVisibilidad]=useState('d-none');
    const [visibilidadSpinner,cambiarVisibilidadSpinner]=useState('d-none');
    if(localStorage.getItem('User_Usuario')) {
        window.location.href="./dashboard";
        return null;
      }

    const iniciarSesion=async()=>{
        cambiarVisibilidad('d-none');
        cambiarVisibilidadSpinner('d-block');
        try{
            
            const datos=await axios.get(BaseURL,{params:{username:usuario,pass:pasword}});
            if (datos.data[0]===true) {
                
                localStorage.setItem('COD_usuario', datos.data[1].COD_usuario);
                localStorage.setItem('User_Usuario', datos.data[1].User_Usuario);
                window.location.href="./inventario";
                cambiarVisibilidadSpinner('d-none');
            } else {
                FmsjeError(datos.data[1]);
                cambiarUsuario('');
                cambiarPasword('');
                cambiarVisibilidad('d-block');
                cambiarVisibilidadSpinner('d-none');
            }
        }catch(err){
            cambiarVisibilidadSpinner('d-none');
            console.log(err);
        }
    }

    const onSubmit=(e)=>{
        e.preventDefault();
        iniciarSesion();
    }
    
    return ( 
        
        <>
        <Container className="mt-5">
                <Row className="justify-content-md-center">
                <Col sm={4}>
                    <div className="col-6 m-auto">
                        <img src={logo_gasex} className="w-100" alt="logo_gasex"></img>
                    </div>
                    <Form onSubmit={onSubmit}>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>Usuario</Form.Label>
                            <Form.Control 
                            type="text" 
                            placeholder="Usuario"
                            value={usuario}
                            onChange={(e)=>{
                                cambiarUsuario(e.target.value);
                            }} 
                            />
                            
                        </Form.Group>

                        <Form.Group controlId="formBasicPassword">
                            <Form.Label>Contraseña</Form.Label>
                            <Form.Control 
                            type="password" 
                            placeholder="Contraseña"
                            value={pasword}
                            onChange={(e)=>{
                                cambiarPasword(e.target.value);
                            }}
                            />
                        </Form.Group>
                        {/*

                    
                        */}
                       <Button variant="primary" type="submit" block className="d-flex align-items-center justify-content-center ">
                       <Spinner animation="border" variant="light" className={`mr-2  ${visibilidadSpinner}`}/>
                        Iniciar Sesión
                    </Button>
                    </Form>
                            <Alert  variant="danger" className={`${visibilidad} mt-2 text-center text-capitalize`}>
                              {msjError}
                            </Alert>

                </Col>
                </Row>
            </Container>
          
        </>

     );
}
 
export default FormInicioSesion;