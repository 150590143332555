import React from 'react';
import { Col, Row, Container, Table, Button } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt,faPencilAlt } from '@fortawesome/free-solid-svg-icons';

const Cilindros = () => {
    return ( 
        <>
        <Container>
            <Row className="mt-5">
                <Col >
                <Table striped bordered hover>
                <thead>
                    <tr>
                    <th>Fecha PH</th>
                    <th>Fecha de Creación</th>
                    <th>Creado por</th>
                    <th>Tipo de Gas</th>
                    <th>Tipo Valvula</th>
                    <th>Volúmen (cm3)</th>
                    <th>Qr</th>
                    <th>Estado</th>
                    <th>Editar</th>
                    <th>Eliminar</th>
                    </tr>
                </thead>
                <tbody>
                    <tr align="center"  className="align-middle">
                        <td>01/01/2021</td>
                        <td>01/01/2021</td>
                        <td>Alexander</td>
                        <td>Helio</td>
                        <td>3hn5</td>
                        <td>200</td>
                        <td><Button className=" bg-transparent border-0 text-secondary text-center m-auto">Ver</Button></td>
                        <td>ok</td>
                        <td ><Button className=" bg-transparent border-0 text-secondary text-center m-auto"><FontAwesomeIcon icon={faPencilAlt}  className="m-auto"/></Button></td>
                        <td ><Button className=" bg-transparent border-0 text-secondary text-center m-auto"><FontAwesomeIcon icon={faTrashAlt}  className="m-auto"/></Button></td>
                    </tr>
                   
                </tbody>
            </Table>
                
                </Col>
            </Row>
        </Container>
            
        </>
     );
}
 
export default Cilindros;